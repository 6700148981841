import { Tabs } from "@/components/molecules";
import { SlugPage } from "@/components/pages";
import { hideUntilFontLoaded } from "@/fonts/font-load";
import { ILocationFragment } from "@/graphql/fragments/__generated__/location.types";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import {
  GetLocationByIdDocument,
  IGetLocationByIdQuery,
  IGetLocationByIdQueryVariables,
} from "@/graphql/queries/__generated__/getLocationById.types";
import {
  GetObituaryBySlugDocument,
  IGetObituaryBySlugQuery,
  IGetObituaryBySlugQueryVariables,
} from "@/graphql/queries/__generated__/getObituaryBySlug.types";
import { getFullName, getNumberOfUpcommingServices } from "@/helpers/obituary";
import logger from "@/logging/client";
import {
  GRAPHQL_SERVICES,
  addApolloState,
  initializeApollo,
} from "@/services/apolloClient";
import { BasicObitBanner as CustomBasicObitBanner } from "@gannettdigital/community-hub-components";
import { NextPage } from "next";
import { useMemo } from "react";
import Error404 from "../404";
import {
  generateTreePlantingUrlFromObit,
  verifyTreePlantingFeatureIsOn,
} from "../api/generateTreePlantingUrl/[slug]";

interface IProps {
  obit?: IObituaryFragment;
  funeralHome: ILocationFragment;
  treePlantingUrl?: string;
  hasMemories?: boolean;
}

const ObitPage: NextPage<IProps> = ({
  obit,
  hasMemories,
  funeralHome,
  treePlantingUrl,
}) => {
  const numberOfUpcommingServices = useMemo(() => {
    return getNumberOfUpcommingServices(obit?.services);
  }, [obit?.services]);

  if (!obit) {
    return <Error404 />;
  }

  return (
    <>
      {/* Note: Load font using FontFace. Using next's Script will not work properly */}
      <script dangerouslySetInnerHTML={{ __html: hideUntilFontLoaded }} />

      <Tabs
        slug={obit.slug}
        hasServices={obit.services.length > 0}
        numberOfUpcommingServices={numberOfUpcommingServices}
      />

      <CustomBasicObitBanner title={getFullName(obit)} />

      <SlugPage
        obituary={obit}
        funeralHome={funeralHome}
        hasMemories={hasMemories}
        treePlantingUrl={treePlantingUrl}
      />
    </>
  );
};

export default ObitPage;

export async function getServerSideProps(Context: {
  req: { params: { slug: string } };
  query: { slug: string };
  res: { statusCode: number };
}) {
  const apolloClient = initializeApollo();
  const defaultProps = {
    obit: null,
    funeralHome: null,
    hasMemories: true,
  };

  try {
    logger.info("rendering detail page", {}, Context.req);

    const slug = Context.req.params.slug || Context.query.slug;

    logger.info("graphql getting obit with variables: ", { slug });
    const { data: getObituaryBySlugData, error: getObituaryBySlugError } =
      await apolloClient.query<
        IGetObituaryBySlugQuery,
        IGetObituaryBySlugQueryVariables
      >({
        query: GetObituaryBySlugDocument,
        variables: { slug },
      });

    if (getObituaryBySlugError) {
      Context.res.statusCode = 500;
      logger.info("garphql got error while getting obituary: ", {
        getObituaryBySlugError,
      });
    }

    if (!getObituaryBySlugData?.obituary.length) {
      logger.info("graphql no obit returned; responding with 404");
      Context.res.statusCode = 404;
      return {
        props: defaultProps,
      };
    }

    const obit = getObituaryBySlugData.obituary[0];

    let funeralHome = null;
    if (obit.historical_obituary?.funeralHome?.external_location_id) {
      const { data: getLocationByIdData, error: getLocationByIdError } =
        await apolloClient.query<
          IGetLocationByIdQuery,
          IGetLocationByIdQueryVariables
        >({
          query: GetLocationByIdDocument,
          variables: {
            id: obit.historical_obituary?.funeralHome?.external_location_id,
          },
          context: {
            serviceName: GRAPHQL_SERVICES.OBITS_LISTINGS,
          },
        });

      if (getLocationByIdError) {
        Context.res.statusCode = 500;
        logger.info("garphql got error while getting obituary: ", {
          getLocationByIdError,
        });
      }

      funeralHome = getLocationByIdData.locations?.[0] || null;
    }

    let treePlantingUrl = "";
    try {
      verifyTreePlantingFeatureIsOn();
      treePlantingUrl = generateTreePlantingUrlFromObit(obit);
    } catch (error: any) {
      logger.error("tree planting url not generated", error);
    }

    return addApolloState(apolloClient, {
      props: {
        ...defaultProps,
        obit,
        funeralHome,
        treePlantingUrl,
      },
    });
  } catch (error: any) {
    Context.res.statusCode = 500;
    logger.error("error rendering detail page", error, Context.req);
  }
  return {
    props: defaultProps,
  };
}
